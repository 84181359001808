<template>
  <!-- Body -->
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <!-- Form -->
    <b-form 
      :class="isInModal ? 'p-2' : 'p-0'" 
      @submit.prevent="handleSubmit(onSubmit)" 
      @reset.prevent="resetForm"
    >
      <!-- Message -->
      <validation-provider #default="validationContext" name=" " rules="required">
        <b-form-group label-for="message">
          <label v-if="isInModal" class="font-weight-bold">{{ $t('products.contact.modal.message') }}*</label>
          <b-form-textarea
            id="message"
            v-model="form.message"
            name=" "
            :placeholder="$t('products.contact.modal.message-placeholder')"
            rows="8"
            rules="required"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div class="float-right" :class="isInModal?'mb-0': 'mb-2'">
        <!-- Missing to implement BE -->
        <b-button variant="primary" :disabled="isSending" type="submit">
          <b-spinner
            v-if="isSending"
            class="mr-25"
            small
            variant="white"
            label="Loading..."
          />
          {{ $t('form.actions.send') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { required } from '@validations';

export default {
  name: 'FileUploadModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    item: {
      type: String,
      required: true,
    },
    modelType: {
      type: String,
      required: true,
    },
    isInModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        message: '',
      },
      required,
      isValid: null,
      isSending: false,
    };
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  methods: {
    async onSubmit() {
      this.isSending = true;
      const key = `${this.modelType}Key`;
      const infoSended = this.form;
      infoSended[key] = this.item;
      infoSended.modelType = this.modelType;
      infoSended.modelKey = this.item;
      try {
        await this.$store.dispatch('sendEmail', infoSended);
        this.$emit('close-modal', false);
        this.notifySuccess(this.$t('share.notify-success'));
        setTimeout(() => {
          this.form.message = '';
        }, 4000);
        this.isSending = false;
      } catch {
        this.notifyError(this.$t('share.notify-error'));
        this.isSending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}
</style>
