<template>
  <div>
    <!-- Assist online button -->
    <b-alert
      variant="info"
      :show="canUnsubscribeOnline && !isHappening"
      class="mt-1"
    >
      {{ $t('events.event-details.not-active-yet') }} {{ event.startDate | moment('D MMM, YYYY, h:mma') }}
    </b-alert>
    <b-button
      v-if="(canSubscribe || canUnsubscribe) && event.startDate && (isOnline || isHybrid)"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mt-1 mr-1 mb-1 mb-sm-0"
      :class="{ 'light-button': canUnsubscribeOnline }"
      :disabled="requestStatus === 'loading' || canUnsubscribeOnsite"
      target="_blank"
      :href="event.registrationURL && canSubscribe ? event.registrationURL : ''"
      @click="handleEventSubscriptionChange()"
    >
      <template v-if="requestStatus === 'loading'">
        <b-spinner small />
        <span> {{ $t(`events.subscription.action.${canSubscribeOnline ? 'subscribing' : 'unsubscribing'}`) }}... </span>
      </template>
      <template v-else>
        <span :class="canUnsubscribeOnline ? 'text-primary' : 'text-white'">
          {{ $t(`events.subscription.action.${canUnsubscribeOnline ? 'unsubscribe-online' : 'subscribe-online'}`) }}
        </span>
      </template>
    </b-button>
    <!-- Assist on-site button -->
    <b-button
      v-if="(canSubscribe || canUnsubscribe) && event.startDate && (!isOnline || isHybrid)"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mt-1 mr-0 mb-1 mb-sm-0"
      :class="{ 'light-button': canUnsubscribeOnsite }"
      :disabled="requestStatus === 'loading' || canUnsubscribeOnline"
      target="_blank"
      :href="event.registrationURL && canSubscribe ? event.registrationURL : ''"
      @click="handleEventSubscriptionChange(true)"
    >
      <template v-if="requestStatus === 'loading'">
        <b-spinner small />
        <span> {{ $t(`events.subscription.action.${canSubscribeOnsite ? 'subscribing' : 'unsubscribing'}`) }}... </span>
      </template>
      <template v-else>
        <span :class="canUnsubscribeOnsite ? 'text-primary' : 'text-white'">
          {{ $t(`events.subscription.action.${canUnsubscribeOnsite ? 'unsubscribe-on-site' : 'subscribe-on-site'}`) }}
        </span>
      </template>
    </b-button>
    <p
      v-if="subscriptionRequestStatus"
      :variant="subscriptionRequestVariant"
      class="py-2 text-primary font-weigth-bold"
    >
      * {{ $t(subscriptionRequestStatus) }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { BButton, BSpinner, BAlert } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import useStoreModule from '@/@core/comp-functions/store/module-registration';
import { EVENTS_STORE_MODULE_NAME, EVENTS_ACTIONS } from '@/views/apps/events/constants/events-store-constants';
import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';
import { SubscriptionMix } from '@copernicsw/community-common';
import ModalityType from '@/views/apps/events/constants/ModalityType';

import useToast from '@core/comp-functions/ui/toast';

export default {
  name: 'EventSubscriptionButton',
  directives: {
    Ripple,
  },
  components: { BButton, BSpinner, BAlert },
  props: {
    event: {
      type: Object,
      required: true,
    },
    isHappening: Boolean,
  },
  setup(props, { emit }) {
    const store = useStoreModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    const { t } = useI18nUtils();
    const toast = useToast();

    const requestStatus = ref('');
    const subscriptionRequestStatus = ref(null);
    const subscriptionType = ref(null);
    const subscriptionRequestVariant = ref(null);
    const errorMessage = t('events.subscription.error-message');

    if (props.event.onlineAttendance) {
      subscriptionType.value = SubscriptionMix.online;
    } else if (props.event.onSiteAttendance) {
      subscriptionType.value = SubscriptionMix.onsite;
    }

    const canSubscribe = computed(() => {
      const today = Date.now();

      if (props.event.inscriptionStartDate && today < props.event.inscriptionStartDate) {
        return false;
      }

      if (props.event.inscriptionEndDate && today > props.event.inscriptionEndDate) {
        return false;
      }

      return !props.event.isAlreadySubscribed;
    });

    const canUnsubscribe = computed(() => {
      const today = Date.now();

      return props.event.isAlreadySubscribed && today < (props.event.endDate || props.event.startDate);
    });
    const canSubscribeOnline = computed(() => canSubscribe && !subscriptionType.value);
    const canUnsubscribeOnline = computed(() => canUnsubscribe && subscriptionType.value === SubscriptionMix.online);
    const canSubscribeOnsite = computed(() => canSubscribe && !subscriptionType.value);
    const canUnsubscribeOnsite = computed(() => canUnsubscribe && subscriptionType.value === SubscriptionMix.onsite);

    const handleEventSubscriptionChange = async (isOnsite) => {
      if (isOnsite) {
        subscriptionType.value = subscriptionType.value === SubscriptionMix.onsite ? null : SubscriptionMix.onsite;
      } else {
        subscriptionType.value = subscriptionType.value === SubscriptionMix.online ? null : SubscriptionMix.online;
      }
      requestStatus.value = 'loading';
      let subscriptionMessageKey = 'events.subscription.response';
      const key = store.getters.currentCollective.key;
      try {
        const response = await store.dispatch(
          canSubscribe.value ? EVENTS_ACTIONS.subscribeEvent : EVENTS_ACTIONS.unsubscribeEvent,
          { eventKey: props.event.key, attendance: subscriptionType.value, communityKey: key },
        );

        if (response.data.subscriptionRequested) {
          // User awaits for subscription acceptance.
          requestStatus.value = 'success';
          subscriptionMessageKey += '.subscription-requested';
          subscriptionRequestVariant.value = 'warning';
        } else if (response.data.invitationNeeded) {
          requestStatus.value = 'fail';
          subscriptionMessageKey += '.invitation-needed';
          subscriptionRequestVariant.value = 'danger';
        } else if (response.data.eventDoesntExist) {
          requestStatus.value = 'fail';
          subscriptionMessageKey += '.event-doesnt-exist';
          subscriptionRequestVariant.value = 'danger';
        } else if (response.data.eventFinished) {
          requestStatus.value = 'fail';
          subscriptionMessageKey += '.event-finished';
          subscriptionRequestVariant.value = 'danger';
        } else if (response.data.eventCanceled) {
          requestStatus.value = 'fail';
          subscriptionMessageKey += '.event-canceled';
          subscriptionRequestVariant.value = 'danger';
        } else if (response.data.eventHasntPlaces) {
          requestStatus.value = 'fail';
          subscriptionMessageKey += '.event-hasnt-places';
          subscriptionRequestVariant.value = 'danger';
        } else if (response.data.isntSubscribed) {
          requestStatus.value = 'fail';
          subscriptionMessageKey += '.isnt-subscribed';
          subscriptionRequestVariant.value = 'danger';
        } else {
          requestStatus.value = 'success';
          subscriptionRequestVariant.value = 'success';
          subscriptionMessageKey += canSubscribe.value ? '.subscription-success' : '.unsubscription-success';
        }

        subscriptionRequestStatus.value = subscriptionMessageKey;

        if (requestStatus.value === 'success') {
          // if it canSubscribe, and it was successful, then its subscribed.
          // if it can't subscribe, then it can unsubscribe, and if it was successful, now its not subscribe.
          const isSubscribed = canSubscribe.value;

          emit('subscription-change', {
            isSubscribed,
            onSiteAttendance: isOnsite ? isSubscribed : null,
            onlineAttendance: !isOnsite ? isSubscribed : null,
          });
        }
      } catch (error) {
        requestStatus.value = '';
        toast({
          props: {
            title: errorMessage,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    };
    const isHybrid = computed(() => props.event.modality === ModalityType.hybrid);
    const isOnline = computed(() => props.event.modality === ModalityType.online);

    return {
      requestStatus,
      canSubscribeOnline,
      canUnsubscribeOnline,
      canSubscribeOnsite,
      canUnsubscribeOnsite,
      subscriptionRequestStatus,
      subscriptionRequestVariant,
      handleEventSubscriptionChange,
      canSubscribe,
      canUnsubscribe,
      isHybrid,
      isOnline,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.filling {
  padding: 0.65rem 3.5rem;
}
.light-button {
  background-color: rgba($primary, 0.2) !important;
  border: none;
}
</style>
