import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

/**
 * Util hook to create toast notifications
 * @returns The toast function to be called to create a new toast notification.
 */
export default function () {
  const toast = useToast();

  return (options) => toast({
    component: ToastificationContent,
    ...options,
  });
}
