<template>
  <div class="w-100">
    <b-row 
      v-if="showContact"
      class="w-100"
    >
      <!-- INFORMACIÓN DE CONTACTO Y RESPONSABLE -->
      <b-col
        cols="12"
        md="7"
        :class="contactClass"
      >
        <div v-if="showSpaceContactData" class="contact-data-box">
          <!-- Contact data title -->
          <p class="mb-1 contact-title">
            {{ $t('common.contact-data') }}
          </p>

          <!-- Emails -->
          <b-row v-if="itemHasEmails">
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t(`${type}.contact.email`) }}
            </b-col>
            <b-col cols="9" md="8" lg="9">
              <div v-for="(email, index) in item.emails" :key="index">
                <small
                  v-if="item.emails.length > 1"
                  class="text-muted mb-0"
                >
                  {{ email.label }}
                </small>
                <p :class="item.emails.length > 1 ? 'mb-25' : ''">
                  {{ email.email }}
                </p>
              </div>
            </b-col>
          </b-row>

          <!-- Email -->
          <b-row v-if="itemHasEmail">
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t(`${type}.contact.email`) }}
            </b-col>
            <b-col cols="9" md="8" lg="9">
              <small class="text-muted mb-0">
                {{ item.label }}
              </small>
              <p :class="item.email ? 'mb-25' : ''">
                {{ item.email }}
              </p>
            </b-col>
          </b-row>

          <!-- Locations -->
          <b-row
            v-if="itemHasLocations"
            class="pt-1"
          >
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t("backoffice.settings.subtitle-locations") }}
            </b-col>
            <b-col cols="9" md="8" lg="9">
              <p v-for="(location, index) in item.locations" :key="index">
                {{ location.address }}
              </p>
            </b-col>
          </b-row>

          <!-- Phones -->
          <b-row
            v-if="itemHasPhones"
            class="pt-1"
          >
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t(`${type}.contact.phone`) }}
            </b-col>
            <b-col
              cols="9"
              md="8"
              lg="9"
              class="d-flex flex-column"
            >
              <div v-for="(phone, index) in item.phones" :key="index">
                <small
                  v-if="item.phones.length > 1"
                  class="text-muted mb-0"
                >
                  {{ phone.label }}
                </small>
                <p :class="item.phones.length > 1 ? 'mb-25' : ''">
                  <span v-if="phone.country">+{{ phone.country.phoneCode }} </span>
                  {{ getPhoneNumber(phone) }}
                </p>
              </div>
            </b-col>
          </b-row>

          <!-- Links -->
          <b-row
            v-if="itemHasLinks"
            class="pt-1"
          >
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t("links.name") }}
            </b-col>
            <b-col
              cols="9"
              md="8"
              lg="9"
              class="d-flex"
            >
              <p v-for="(link, index) in item.links" :key="index">
                <a :href="link.url" target="_blank" class="mr-1">
                  <feather-icon
                    :icon="
                      link.label === 'Website' ? 'GlobeIcon' : `${link.label}Icon`
                    "
                    size="18"
                    class="social-icons"
                  />
                </a>
              </p>
            </b-col>
          </b-row>
        </div>
        <!-- End responsible -->

        <div v-if="showResponsible">
          <!-- Responsible title -->
          <p v-if="showResponsibleTitle" class="mb-1 contact-title">
            {{ $t(`${type}.contact.responsible`) }}
          </p>

          <!-- Image -->
          <div class="d-flex align-items-center class pb-2 mb-2">
            <b-avatar
              size="25"
              :src="
                item.poweredByType === 'member'
                  ? item.poweredBy.avatar_url
                  : item.poweredBy.logoURL
              "
            />

            <!-- Link to space -->
            <b-link
              v-if="item.poweredByType === 'community'"
              :href="getCollectiveUrl(item.poweredBy)"
            >
              <h4 class="ml-1 mb-0">
                {{ firstToUpperCase(item.poweredBy.name) }}
              </h4>
            </b-link>

            <!-- Link to member -->
            <b-link
              v-else-if="item.poweredByType === 'member'"
              :to="getMemberLocation(item.poweredBy)"
            >
              <h4 class="ml-1 mb-0">
                {{ firstToUpperCase(item.poweredBy.name) }}
                <span v-if="item.poweredBy.surname">
                  {{ firstToUpperCase(item.poweredBy.surname) }}
                </span>
              </h4>
            </b-link>
            
            <!-- Link to org -->
            <b-link 
              v-else :to="getOrganizationLocation(item.poweredBy)"
            >
              <h4 class="ml-1 mb-0">
                {{ firstToUpperCase(item.poweredBy.name) }}
                <span v-if="item.poweredBy.surname">
                  {{ firstToUpperCase(item.poweredBy.surname) }}
                </span>
              </h4>
            </b-link>
          </div>

          <!-- Emails -->
          <b-row v-if="poweredByHasEmails">
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t(`${type}.contact.email`) }}
            </b-col>
            <b-col cols="9" md="8" lg="9">
              <div v-for="(email, index) in item.poweredBy.emails" :key="index">
                <small
                  v-if="item.poweredBy.emails.length > 1"
                  class="text-muted mb-0"
                >
                  {{ email.label }}
                </small>
                <p :class="item.poweredBy.emails.length > 1 ? 'mb-25' : ''">
                  {{ email.email }}
                </p>
              </div>
            </b-col>
          </b-row>

          <!-- Email -->
          <b-row v-if="poweredByHasEmail">
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t(`${type}.contact.email`) }}
            </b-col>
            <b-col cols="9" md="8" lg="9">
              <small class="text-muted mb-0">
                {{ item.poweredBy.label }}
              </small>
              <p :class="item.poweredBy.email ? 'mb-25' : ''">
                {{ item.poweredBy.email }}
              </p>
            </b-col>
          </b-row>

          <!-- Locations -->
          <b-row
            v-if="poweredByHasLocations"
            class="pt-1"
          >
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t("backoffice.settings.subtitle-locations") }}
            </b-col>
            <b-col cols="9" md="8" lg="9">
              <p v-for="(location, index) in item.poweredBy.locations" :key="index">
                {{ location.address }}
              </p>
            </b-col>
          </b-row>

          <!-- Phones -->
          <b-row
            v-if="poweredByHasPhones"
            class="pt-1"
          >
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t(`${type}.contact.phone`) }}
            </b-col>
            <b-col
              cols="9"
              md="8"
              lg="9"
              class="d-flex flex-column"
            >
              <div v-for="(phone, index) in item.poweredBy.phones" :key="index">
                <small
                  v-if="item.poweredBy.phones.length > 1"
                  class="text-muted mb-0"
                >
                  {{ phone.label }}
                </small>
                <p :class="item.poweredBy.phones.length > 1 ? 'mb-25' : ''">
                  <span v-if="phone.country">+{{ phone.country.phoneCode }} </span>
                  {{ getPhoneNumber(phone) }}
                </p>
              </div>
            </b-col>
          </b-row>

          <!-- Links -->
          <b-row
            v-if="poweredByHasLinks"
            class="pt-1"
          >
            <b-col
              cols="3"
              md="4"
              lg="3"
              class="font-weight-bold"
            >
              {{ $t("links.name") }}
            </b-col>
            <b-col
              cols="9"
              md="8"
              lg="9"
              class="d-flex"
            >
              <p v-for="(link, index) in item.poweredBy.links" :key="index">
                <a :href="link.url" target="_blank" class="mr-1">
                  <feather-icon
                    :icon="
                      link.label === 'Website' ? 'GlobeIcon' : `${link.label}Icon`
                    "
                    size="18"
                    class="social-icons"
                  />
                </a>
              </p>
            </b-col>
          </b-row>
        </div>
        <!-- End responsible -->
      </b-col>

      <!-- CONTACTAR -->
      <b-col
        v-if="showContactModal"
        cols="12"
        md="5"
      >
        <p class="mb-1 contact-area-title">
          {{ $t("products.contact.title") }}
        </p>
        
        <!-- Contact modal -->
        <contact-modal
          :is-in-modal="false"
          :model-type="typeSingle"
          :item="item.key"
          @close-modal="isContactModalVisible = false"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      No contact data.
    </b-row>
  </div>
</template>

<script>
import ContactModal from '@/@core/components/modal/ContactModal.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { getCollectiveUrl } from '@/@core/utils/collective';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {
    ContactModal,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    typeSingle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canEdit() {
      return checkPermissions(
        'update',
        'service',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    // ITEM contact data
    itemHasEmail() {
      return this.item.email && this.item.email !== '';
    },
    itemHasEmails() {
      return this.item.emails && this.item.emails.length > 0;
    },
    itemHasPhones() {
      return this.item.phones && this.item.phones.length > 0;
    },
    itemHasLinks() {
      return this.item.links && this.item.links.length > 0;
    },
    itemHasLocations() {
      return this.item.locations && this.item.locations.length > 0;
    },
    // POWERED BY contact data
    poweredByHasEmail() {
      return this.item.poweredByType !== 'member' && this.item.poweredBy.email && this.item.poweredBy.email !== '';
    },
    poweredByHasEmails() {
      return this.item.poweredBy.emails && this.item.poweredBy.emails.length > 0;
    },
    poweredByHasPhones() {
      return this.item.poweredBy.phones && this.item.poweredBy.phones.length > 0;
    },
    poweredByHasLinks() {
      return this.item.poweredBy.links && this.item.poweredBy.links.length > 0;
    },
    poweredByHasLocations() {
      return this.item.poweredBy.locations && this.item.poweredBy.locations.length > 0;
    },
    showResponsible() {
      return this.item.poweredBy;
    },
    showSpaceContactData() {
      return (this.itemHasEmail || this.itemHasEmails || this.itemHasPhones || this.itemHasLinks || this.itemHasLocations); //this.item.mainType !== 'Organization' && 
    },
    showContactModal() {
      return this.poweredByHasEmail || this.poweredByHasEmails || this.itemHasEmail || this.itemHasEmails;
    },
    showResponsibleTitle() {
      return this.type !== 'event-agenda';
    },
    showContact() {
      return this.item.mainType ? true : this.item.visibility;
    },
    contactClass() {
      return this.showContact ? '' : 'hide-contact' || this.type === 'agenda' ? '' : 'hide-contact';
    }
  },
  async created() {
    //
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    firstToUpperCase(item) {
      const name = typeof item.key === 'string' ? item : this.translate(item);
      const firstLetter = name[0];
      if(!firstLetter){
        return '?';
      }
      return firstLetter.toUpperCase() + name.substring(1);
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }
      return {
        name: 'member-details',
        params: {
          username: user.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    getCollectiveUrl(item, params = '') {
      if (item != null) {
        return getCollectiveUrl(item, params);
      }
      return '';
    },
    getOrganizationLocation(organization) {
      if (!organization.name) {
        return null;
      }

      return {
        name: 'organization',
        params: {
          slug: organization.slug,
          communityId: this.$route.params.communityId,
        },
      };
    },
    getPhoneNumber(phone){
      return phone.phone ?? phone.number;
    },
  },
};
</script>

<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  max-height: 150px !important;
}
.hide-contact {
  opacity: 0.3;
}
.contact-data-box {
  margin-bottom: 3rem;
}
.contact-area-title {
  font-size: 16px;
}
.contact-title {
  font-size: 20px;
  margin-bottom: 1rem;
  padding-bottom: 0.7rem;
  width: 90%;
  border-bottom: 1px solid lightgrey;
}
</style>
