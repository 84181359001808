<template>
  <component :is="tag" class="event-period">
    <template v-if="dates">
      {{ dates }} ({{ $t('events.event-details.local-time') }})
    </template>
    <!-- <template v-else>
      {{ $t('events.event-details.no-dates-yet') }}
    </template> -->
  </component>
</template>

<script>
import { getEventDatesRange } from '@/views/apps/events/utils/event-utils';

export default {
  name: 'EventsDetailsPeriod',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    event: {
      type: Object,
      required: true,
    },
    startDate: {
      type: Number,
    },
    endDate: {
      type: Number,
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    dates() {
      return getEventDatesRange(this.startDate, this.endDate, this.event.allDay, this.locale);
    },
  },
};
</script>
<style lang="scss" scoped>
.event-period {
  line-height: 1.7rem !important;
  text-transform: capitalize;
}
</style>
