<template>
  <div
    v-if="event.startDate"
    class="border rounded p-2 event-container"
    style="max-width: 500px"
    :class="isHappeningNow ? 'border-color-primary border-3' : 'border-2'"
  >
    <b-link class="text-dark text-wrap" @click="openEvent(event)">
      <b-img
        v-if="event.thumbnailURL"
        :src="thumbnailURL"
        class="event-thumbnail mb-1"
        :alt="title"
      />
      <!-- Hour -->
      <div
        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
      >
        <event-period
          tag="h5"
          class="mb-1"
          :class="isOld ? 'text-muted' : 'text-primary'"
          :start-date="event.startDate"
          :end-date="event.endDate"
          :all-day="event.allDay"
        />
      </div>
      <!-- Title -->
      <div class="text-title" :class="{ 'text-muted': isOld }">
        {{ title }}
      </div>
      <!-- Speaker -->
      <b-media v-if="getSpeaker(event)">
        <template #aside>
          <b-avatar :src="getSpeaker(event).avatarURL" />
        </template>
        <h6>{{ getSpeaker(event).name }} {{ getSpeaker(event).surname }}</h6>
        <p>{{ getSpeakerHeadline(event) }}</p>
      </b-media>
    </b-link>
  </div>
</template>

<script>
import {
  BMedia, BAvatar, BLink, BImg,
} from 'bootstrap-vue';
import { EventType } from '@copernicsw/community-common';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import EventPeriod from '../EventPeriod.vue';

export default {
  name: 'EventTimelineItem',
  components: {
    BMedia,
    BAvatar,
    BLink,
    BImg,
    EventPeriod,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    date: {
      type: [Number, String, Object], // Date valid entity
      required: true,
    },
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    timelineMoment() {
      return this.$moment(this.date);
    },
    actualStartMoment() {
      const start = this.$moment(this.event.startDate);

      return this.event.allDay || start.isSameOrBefore(this.timelineMoment)
        ? this.$moment(this.date).startOf('day')
        : start;
    },
    actualEndMoment() {
      if (this.event.allDay) {
        return this.$moment(this.date).endOf('day');
      }

      if (this.event.endDate) {
        const end = this.$moment(this.event.endDate);
        return end.isSameOrAfter(this.timelineMoment)
          ? this.$moment(this.date).endOf('day')
          : end;
      }

      return this.$moment(this.event.startDate).add(30, 'min');
    },
    isOld() {
      return this.actualEndMoment.isBefore(this.$moment());
    },
    isHappeningNow() {
      return this.$moment().isBetween(
        this.actualStartMoment,
        this.actualEndMoment,
        undefined,
        '[]',
      );
    },
    thumbnailURL() {
      return getImageResource(this.event.thumbnailURL);
    },
    title() {
      return translateTranslationTable(
        this.locale,
        this.event.title || this.event.name,
      );
    },
  },
  methods: {
    getSpeaker(event) {
      // FIXME: have to filter the people to obtain only the speakers
      return event.people ? event.people[0] : null;
    },
    getSpeakerHeadline(event) {
      const speaker = this.getSpeaker(event);

      return speaker?.headline
        ? translateTranslationTable(this.locale, speaker?.headline)
        : '';
    },
    isMeeting(event) {
      return event.type === EventType.Meeting;
    },
    openEvent(event) {
      this.$emit('open-event', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.event-container {
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
  margin: 1.2em auto;
  background-color: #fff;
  width: 91%;
  height: fit-content;
}
.text-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.event-thumbnail {
  border-radius: $border-radius-lg;
  overflow: hidden;
  width: 100%;
  height: 120px;
  object-fit: cover;
}
</style>
