var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-events-horizontal-list',{staticClass:"events-agenda h-100",class:{ 'events-agenda--fixed': !_vm.isScrollable && _vm.centered },attrs:{"load-next-page":_vm.loadNextPage,"load-previous-page":_vm.loadPreviousPage,"item-class":[
    'h-100',
    'events-timeline-container',
    ("events-timeline-size-" + _vm.eventsLength) ],"forced-events":_vm.computedForcedEvents},on:{"events-updated":_vm.handleEventsUpdated,"update:scrollable":function($event){_vm.isScrollable = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var item = ref.item;
return [(item.startDate)?_c('div',{staticClass:"d-flex flex-column h-100"},[_c('h5',{staticClass:"mb-2 py-1 text-center font-weight-bolder border-bottom border-3",class:_vm.isOld(item) ? 'text-muted' : 'border-color-primary'},[_vm._v(" "+_vm._s(_vm.$d(new Date(item.startDate), "short"))+" ")]),_c('app-scroll',{staticClass:"events-timeline pb-3",attrs:{"options":{ scrollPanel: { scrollingX: false } }}},_vm._l((item.events),function(event,eventIndex){return _c('event-timeline-item',{key:eventIndex,class:{ 'mt-1': eventIndex },attrs:{"event":event,"date":item.startDate},on:{"open-event":_vm.handleOpenEvent}})}),1)],1):_vm._e()]}},{key:"footer",fn:function(ref){
  var updateEvent = ref.updateEvent;
return [(_vm.selectedEvent)?_c('event-modal',{attrs:{"is-membership":_vm.isMembership,"event":_vm.selectedEvent,"is-from-event":_vm.isFromEvent,"community":_vm.community},on:{"subscription-change":function($event){return _vm.handleSubscriptionChange($event, updateEvent)},"edited-event":function($event){return _vm.handleEditVal()},"close-modal":function($event){return _vm.handleCloseModal()}},model:{value:(_vm.isModalVisible),callback:function ($$v) {_vm.isModalVisible=$$v},expression:"isModalVisible"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }