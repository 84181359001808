<template>
  <b-button
    v-if="canJoinOnlineEvent"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    class="ml-1 mr-0 mb-1 mb-sm-0"
    :class="{ 'text-muted': isOver }"
    :href="meetingLocation"
    target="_blank"
    :to="!event.joinUrl ? eventRoomLocation : undefined"
    v-bind="$attrs"
  >
    <template v-if="hasJoinLink">
    {{ event.room ? event.room.name : translatedTitle }}
    </template>
    <template v-else>
      {{ $t('events.details.action.join') }}
    </template>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'EventJoinButton',
  directives: {
    Ripple,
  },
  components: { BButton },
  props: {
    event: {
      type: Object,
      required: true,
    },
    isOver: Boolean,
    hasJoinLink: Boolean,
  },
  computed: {
    meetingLocation() {
      if (this.event.joinUrl) {
        return this.event.joinUrl;
      }
      return undefined;
    },
    canJoinOnlineEvent() {
      // it opens 10 minutes before the start date
      return !this.isOver
        && (this.event.isAccepted || this.event.onlineAttendance || this.event.isSection)
        && this.$moment(this.event.startDate).subtract(10, 'minutes').isSameOrBefore(Date.now(), 'minute');
    },
    translatedTitle() {
      return this.event ? translateTranslationTable(this.$store.state.locale.currentLocale, this.event.title) : '';
    },
    eventRoomLocation() {
      return this.event.room ? {
        name: 'event-room',
        params: {
          communityId: this.$route.params.communityId,
          name: this.event.room.name,
          id: this.event.key,
        },
      } : {
        name: 'event-room',
        params: {
          communityId: this.$route.params.communityId,
          id: this.event.key,
        },
      };
    },
  },
};
</script>
