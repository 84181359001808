<template>
  <div>
    <!-- Date -->
    <div class="d-flex mb-1 content-container">
      <feather-icon icon="CalendarIcon" size="19" class="text-dark mr-1" />
      <div :class="{ 'text-muted': isOver }">
        <events-details-period
          :event="event"
          :start-date="startDate"
          :end-date="endDate"
          tag="p"
          class="font-weight-bolder mb-0"
        />

        <b-button
          v-if="event.startDate"
          variant="link"
          class="p-0 calendar-link"
          :class="{ 'text-muted': isOver }"
          @click="$emit('adding-calendar', true)"
        >
          {{ $t("events.event-details.add-to-calendar") }}
        </b-button>
      </div>
    </div>
    <div v-if="event.modality" :class="{ 'text-muted': isOver }">
      <div
        v-if="
          event.modality === 'online' ||
            event.modality === 'hybrid'
        "
        class="d-flex"
      >
        <feather-icon icon="VideoIcon" size="19" class="text-dark mr-1" />
        <p class="font-weight-bolder mb-0">
          {{ $t("events.event-details.online-event") }}
        </p>
      </div>
      <div
        v-if="
          event.modality === 'on-site' ||
            event.modality === 'hybrid'
        "
        class="d-flex my-1"
      >
        <feather-icon icon="MapPinIcon" size="19" class="text-dark mr-1" />
        <div>
          <p class="font-weight-bolder mb-0">
            {{ $t("events.event-details.on-site-event") }}
          </p>
          <b-button
            v-if="hasLocations"
            variant="link"
            class="p-0 calendar-link"
            :class="{ 'text-muted': isOver }"
            :href="meetingLocation"
            @click="$emit('see-map')"
          >
            {{ $t("events.event-details.see-map") }}
          </b-button>
        </div>
      </div>
      <div>
        <event-join-button
          v-if="canJoinOnlineEvent"
          :event="event"
          :is-over="isOver"
          has-join-link
          variant="link"
        />
      </div>
    </div>
    <div v-else :class="{ 'text-muted': isOver }">
      <div v-if="isOnline || isHybrid" class="d-flex">
        <feather-icon icon="VideoIcon" size="19" class="text-dark mr-1" />
        <p class="font-weight-bolder mb-0">
          {{ $t("events.event-details.online-event") }}
        </p>
      </div>
      <div v-if="!isOnline || isHybrid" class="d-flex my-1">
        <feather-icon icon="MapPinIcon" size="19" class="text-dark mr-1" />
        <div>
          <p class="font-weight-bolder mb-0">
            {{ $t("events.event-details.on-site-event") }}
          </p>
          <b-button
            v-if="hasLocations"
            variant="link"
            class="p-0 calendar-link"
            :class="{ 'text-muted': isOver }"
            :href="meetingLocation"
            @click="$emit('see-map')"
          >
            {{ $t("events.event-details.see-map") }}
          </b-button>
        </div>
      </div>
      <div>
        <event-join-button
          v-if="canJoinOnlineEvent"
          :event="event"
          :is-over="isOver"
          has-join-link
          variant="link"
        />
      </div>
    </div>
    <div v-if="event.poweredBy != null">
      <b-button
        v-if="!showResponsible"
        variant="primary"
        size="sm"
        class="font-weight-bold mt-2"
        @click="showResponsible = true"
      >
        Show responsible
      </b-button>
      <b-button
        v-if="showResponsible"
        variant="primary"
        size="sm"
        class="font-weight-bold mt-2"
        @click="showResponsible = false"
      >
        Hide Information
      </b-button>
      <div v-if="showResponsible" class="mt-2">
        <contact :item="event" type="event-agenda" type-single="event-agenda" />
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import EventsDetailsPeriod from '@/views/apps/events/components/EventsDetailsPeriod.vue';
import EventJoinButton from '@/views/apps/events/components/actions/EventJoinButton.vue';
import ModalityType from '@/views/apps/events/constants/ModalityType';
import Contact from '@core/components/contact/Contact.vue';

export default {
  components: {
    BButton, EventsDetailsPeriod, EventJoinButton, Contact,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    startDate: {
      type: Number,
    },
    endDate: {
      type: Number,
    },
    modality: {
      type: String,
    },
    isOver: Boolean,
    hasJoinLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isAddingToExternalCalendar: false,
      showResponsible: false,
    };
  },
  computed: {
    isOnline() {
      // Api returns 'online', 'on-site' or 'hybrid'
      return this.event.modality === ModalityType.online;
    },
    isHybrid() {
      return (
        this.event.modality === ModalityType.hybrid || this.event.modality === 2
      );
    },
    hasLocations() {
      return (
        !this.isOnline
        && this.event.locations
        && this.event.locations.length > 0
      );
    },
    meetingLocation() {
      if (!this.isOnline) {
        return '#locations-map';
      }
      return undefined;
    },
    canJoinOnlineEvent() {
      // it opens 10 minutes before the start date
      return (
        !this.isOver
        && (this.event.isAccepted
          || this.event.onlineAttendance
          || this.event.isSection)
        && this.$moment(this.event.startDate)
          .subtract(10, 'minutes')
          .isSameOrBefore(Date.now(), 'minute')
      );
    },
  },
  methods: {
    handleAddToCalendar() {
      this.isAddingToExternalCalendar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-link {
  margin-top: 0.5rem;
}
</style>
