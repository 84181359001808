<template>
  <base-events-horizontal-list
    class="events-agenda h-100"
    :class="{ 'events-agenda--fixed': !isScrollable && centered }"
    :load-next-page="loadNextPage"
    :load-previous-page="loadPreviousPage"
    :item-class="[
      'h-100',
      'events-timeline-container',
      `events-timeline-size-${eventsLength}`,
    ]"
    :forced-events="computedForcedEvents"
    @events-updated="handleEventsUpdated"
    @update:scrollable="isScrollable = $event"
  >
    <template #default="{ item }">
      <div v-if="item.startDate" class="d-flex flex-column h-100">
        <h5
          class="mb-2 py-1 text-center font-weight-bolder border-bottom border-3"
          :class="isOld(item) ? 'text-muted' : 'border-color-primary'"
        >
          {{ $d(new Date(item.startDate), "short") }}
        </h5>
        <app-scroll
          class="events-timeline pb-3"
          :options="{ scrollPanel: { scrollingX: false } }"
        >
          <event-timeline-item
            v-for="(event, eventIndex) in item.events"
            :key="eventIndex"
            :event="event"
            :date="item.startDate"
            :class="{ 'mt-1': eventIndex }"
            @open-event="handleOpenEvent"
          />
        </app-scroll>
      </div>
    </template>

    <template #footer="{ updateEvent }">
      <event-modal
        v-if="selectedEvent"
        v-model="isModalVisible"
        :is-membership="isMembership"
        :event="selectedEvent"
        :is-from-event="isFromEvent"
        :community="community"
        @subscription-change="handleSubscriptionChange($event, updateEvent)"
        @edited-event="handleEditVal()"
        @close-modal="handleCloseModal()"
      />
    </template>
  </base-events-horizontal-list>
</template>

<script>
import AppScroll from '@core/components/app-scroll/AppScroll.vue';
//import { EventType, EventPackages } from '@copernicsw/community-common';
import {
  EVENTS_STORE_MODULE_NAME,
  //EVENTS_ACTIONS,
} from '@/views/apps/events/constants/events-store-constants';
import eventsStoreModule from '@/views/apps/events/store/eventsStoreModule';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
//import OrderBy from '@core/constants/OrderBy';
import EventTimelineItem from './EventTimelineItem.vue';
import EventModal from '../EventModal.vue';
import BaseEventsHorizontalList from '../BaseEventsHorizontalList.vue';

export default {
  name: 'EventsHorizontalList',
  components: {
    AppScroll,
    BaseEventsHorizontalList,
    EventTimelineItem,
    EventModal,
  },
  props: {
    forcedEvents: {
      type: Array,
      default: null,
    },
    centered: {
      type: Boolean,
      default: true,
    },
    subcommunityCollectiveKey: {
      type: String,
      default: null,
    },
    isFromEvent: {
      type: Boolean,
      default: false,
    },
    isMembership: Boolean,
    community: {
      type: Object,
    },
  },
  data() {
    return {
      isModalVisible: false,
      selectedEvent: null,
      isScrollable: true,
      eventsLength: 0,
      editKey: 1,
      total: 0,
      page: 0,
      grouped: [],
    };
  },
  computed: {
    currentCollectiveKey() {
      return this.$store.getters.currentCollective.key;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    computedForcedEvents() {
      if (!this.forcedEvents) return null;
      return this.groupItemsByStartDate(this.forcedEvents);
    },
  },
  async created() {
    if (!this.$store.hasModule(EVENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(EVENTS_STORE_MODULE_NAME, eventsStoreModule);
    }
  },
  methods: {
    handleEditVal() {
      this.handleCloseModal();
      this.$emit('update-page');
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'events',
        page: this.page,
        perPage: 100,
        communitySlug: this.community?.slug || this.collective.slug,
        customName: 'agenda',
        requestConfig: {
          visibleOncommunityKey: this.community?.key || this.collective.key,
          isForAgenda: true,
        },
      });
    },
    async loadNextPage({ lastDate }) {
      if (this.page === 0 || this.total > this.eventsLength) {
        let sinceStartDate;
        if (!lastDate) {
          sinceStartDate = new Date();
        } else {
          sinceStartDate = new Date(lastDate);
          sinceStartDate.setDate(sinceStartDate.getDate() + 1);
        }
        sinceStartDate.setHours(0);
        sinceStartDate.setMinutes(0);
        sinceStartDate.setSeconds(0);
        sinceStartDate.setMilliseconds(0);
        this.page += 1;
        await this.fetchData();
        const response = this.$store.getters.agenda;
        this.total = response.meta ? response.meta.total : 0;
        this.eventsLength = response.unpaginated?.length;
        if (this.page === 1 || this.total > this.eventsLength) {
          return this.groupItemsByStartDate(response.unpaginated);
        }
      }
      return [];
    },
    async loadPreviousPage({ firstDate }) {
      if (this.page > 1) {
        let untilStartDate;
        if (!firstDate) {
          untilStartDate = new Date();
        } else {
          untilStartDate = new Date(firstDate);
        }

        untilStartDate.setHours(0);
        untilStartDate.setMinutes(0);
        untilStartDate.setSeconds(0);
        untilStartDate.setMilliseconds(0);
        this.page = this.page > 1 ? (this.page -= 1) : this.page;
        await this.fetchData();
        const response = this.$store.getters.agenda;
        return this.groupItemsByStartDate(response.unpaginated.reverse());
      }
      return [];
    },
    handleCloseModal() {
      (this.selectedEvent = null), (this.isModalVisible = false);
    },
    handleOpenEvent(event) {
      this.selectedEvent = event;
      this.isModalVisible = true;
    },
    handleEventsUpdated(events) {
      this.eventsLength = events.length;
      this.$emit('events-updated', events);
    },
    async handleSubscriptionChange(
      { isSubscribed, onSiteAttendance, onlineAttendance },
      updateEvent,
    ) {
      this.selectedEvent.isAlreadySubscribed = isSubscribed;
      if (onSiteAttendance !== null) {
        this.selectedEvent.onSiteAttendance = onSiteAttendance;
      }
      if (onlineAttendance !== null) {
        this.selectedEvent.onlineAttendance = onlineAttendance;
      }
      updateEvent(this.selectedEvent);
    },
    isOld({ startDate }) {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      return startDate < today.getTime();
    },
    /**
     * Groups a list of events into a list of objects with startDate and the array of all the events for that given date
     */
    groupItemsByStartDate(data) {
      if (this.total === 0 || this.eventsLength >= this.total) {
        this.grouped = [];
      }
      if(data){
        this.grouped = Object.values(
          data.reduce((groups, event) => {
            let updatedGroups = {};
            updatedGroups = { ...groups };

            const startDay = this.$moment(event.startDate);
            startDay.startOf('day');

            const startDayTime = startDay.valueOf();
            if (startDayTime) {
              updatedGroups[startDayTime] = {
                startDate: startDayTime,
                events: [...(groups[startDayTime]?.events || []), event],
              };
            }
            if (event.endDate) {
              const endDay = this.$moment(event.endDate);
              endDay.endOf('day');
              const endDayTime = endDay.valueOf();

              const oneDay = 24 * 60 * 60 * 1000;
              let index = 0;
              for (
                let dayTime = startDayTime + oneDay;
                dayTime <= endDayTime;
                dayTime += oneDay
              ) {
                if (index === 100) {
                  break;
                }
                updatedGroups[dayTime] = {
                  startDate: dayTime,
                  events: [...(groups[dayTime]?.events || []), event],
                };
                index++;
              }
            }
            return updatedGroups;
          }, {}),
        );
      }
      return this.grouped;
    },
    translateEventTitle(event) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        event?.title,
      );
    },
    translateEventDescription(event) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        event?.description,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.events-timeline {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  text-align: left;
}
.events-agenda {
  overflow-y: hidden;
  > .not-scrollable > div {
    height: 100%;
  }
}

.events-agenda.events-agenda--fixed::v-deep {
  text-align: center;

  .events-timeline-container {
    display: inline-block;
  }

  .events-timeline-size-1 {
    width: 75%;
  }
  .events-timeline-size-2 {
    @media (min-width: 768px) {
      width: calc(100% / 2 - #{$spacer * 2});
    }
  }
  .events-timeline-size-2 {
    @media (min-width: 768px) {
      width: calc(100% / 2 - #{$spacer * 2});
    }
  }
  .events-timeline-size-3 {
    @media (min-width: 768px) {
      width: calc(100% / 3 - #{$spacer * 2});
    }
  }
  .events-timeline-size-4 {
    @media (min-width: 992px) {
      width: calc(100% / 4 - #{$spacer * 2});
    }
  }
}

.events-agenda::v-deep .events-timeline-container {
  width: calc(100% - #{$spacer * 2});
  // This is a temporal fix until we have
  // a ResizeObserver.
  // Once that is implemented the format of the agenda will adapt
  // with the contaoner's width, instead of the document's width.
  // Then no min-width will be necesary
  min-width: 200px;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .events-agenda::v-deep .events-timeline-container {
    width: calc(100% / 3 - #{$spacer * 2});
  }
}

// Large
@media (min-width: 992px) {
  .events-agenda::v-deep .events-timeline-container {
    width: calc(100% / 4 - #{$spacer * 2});
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .events-agenda::v-deep .events-timeline-container {
    width: calc(100% / 5 - #{$spacer * 2});
  }
}
</style>
